/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import "./utils/dayjs";

import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import React, { startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

import { ClientStyleContext } from "./styles/client-style-context";
import { createEmotionCache } from "./styles/create-emotion-cache";
import { theme } from "./utils/theme";

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = React.useState(createEmotionCache());

  const reset = React.useCallback(() => {
    setCache(createEmotionCache());
  }, []);

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

function hydrate() {
  startTransition(() => {
    hydrateRoot(
      document,
      <ClientCacheProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RemixBrowser />
          </ThemeProvider>
        </StyledEngineProvider>
      </ClientCacheProvider>,
    );
  });
}

// Follow the MUI example from: https://github.com/mui/material-ui/blob/master/examples/material-ui-remix-ts/app/entry.client.tsx
if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

process.env.NODE_ENV === "production" &&
  window.env.SENTRY_DSN &&
  Sentry.init({
    release: window.env.SENTRY_RELEASE,
    environment: window.env.TARGET_ENV ?? process.env.NODE_ENV,
    dsn: window.env.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches,
        ),
      }),
      new Sentry.Replay({ maskAllText: false, maskAllInputs: false }),
    ],
    // Performance Monitoring
    tracesSampleRate: window.env.TARGET_ENV === "production" ? 0.01 : 1,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api.*\.wishtender\.com/],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.001,
    // allow and deny urls
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Intercom
      /js\.intercomcdn\.com/i,
    ],
    beforeSend: (event, hint) => {
      // @see https://github.com/getsentry/sentry-javascript/issues/3045#issuecomment-726450247
      try {
        if (
          event?.exception?.values?.[0]?.stacktrace?.frames?.[0].filename?.includes(
            "js.intercomcdn.com",
          )
        ) {
          // function expects null as return value
          // eslint-disable-next-line unicorn/no-null
          return null;
        }
      } catch {}

      return event;
    },
  });
